import imageUrlBuilder from '@sanity/image-url'

const imageUrlFor = (source) => {
  const builder = imageUrlBuilder({
    projectId: process.env.SANITY_PROJECT_ID || process.env.GATSBY_SANITY_PROJECT_ID,
    dataset: process.env.SANITY_DATASET || process.env.GATSBY_SANITY_DATASET,
  })
  return builder.image(source)
}

export default imageUrlFor
