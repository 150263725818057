import React from "react"
import PropTypes from "prop-types"
import { connect as connectFela } from "react-fela"

import fluidValues from "utilities/fluidValues"
import { fontFamilyMonospace } from "gatsby-theme-fela/fonts"
import BlockContent from '@sanity/block-content-to-react'
import H5 from "components/typography/H5"
import P from "components/typography/P"
import { serializers as linkSerializers } from "components/BaseLink.js"

const serializers = ({styles, rules}) => {
  return {
    types: {
      block: (props) => {
        const style = props.node.style

        if (style === 'xlarge') {
          return <P extend={{styles: rules.xlarge}} version="xlarge">{props.children}</P>
        }

        if (style === 'title') {
          return <H5 extend={{styles: rules.title}}>{props.children}</H5>
        }

        if (style === 'large') {
          return <P version='large'>{props.children}</P>
        }

        if (style === 'normal') {
          return <P version='body' extend={{styles: rules.normal}}>{props.children}</P>
        }
        return BlockContent.defaultSerializers.types.block(props)
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      highlight: props => (
        <span className={styles.highlight}>
          {props.children}
        </span>
      ),
    }
  }
}

/**
* Image component, used to display images inside a Content Block
*/
const ContentBlockWithShadow = ({ styles, rules, node: { content }}) => {
  return <div className={styles.container}>
    <div className={styles.imageShadow}></div>
    <div className={styles.frame}>
      <BlockContent className={styles.content} blocks={content} serializers={serializers({rules, styles})} />
    </div>
  </div>
}

const corner = {
    onAfter: {
      display: 'block',
      content: '""',
      backgroundColor: 'black',
      position: 'absolute',
      top: -6,
      left: -6,
      zIndex: -1,
      ...fluidValues({width: [32, 75], height: [32, 67]})
    }
}

const styles  = ({node: { image }}) => ({
  container: {
    ...fluidValues({
      paddingRight: [24, 76],
      paddingBottom:  [24, 76],
    }),
    boxSizing: 'border-box',
    margin: '0 auto',
    maxWidth: 622,
    position: 'relative',
    width: '100%',
  },
  imageShadow: {
    ...fluidValues({
      top: [24, 76],
      left: [24, 76],
    }),
    backgroundImage: image ? `url("${image.asset.url}")` : 'url()',
    bottom: 0,
    display: 'block',
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    zIndex: -1,
  },
  frame: {
    ...fluidValues({
      paddingTop: [35, 60],
      paddingLeft: [25, 67],
      paddingBottom: [35, 42],
      paddingRight: [25, 67],
    }),
    ...corner,
    backgroundColor: 'white',
    boxSizing: 'border-box',
    height: '100%',
    width: '100%',
  },
  normal: {
    '&:last-child': {
      marginBottom: 0,
      marginTop: 0,
    }
  },
  button: {
    width: '100%',
    boxSizing: 'border-box',
    ...fluidValues({marginTop: [6, 42]}),
  },
  title: {
    ...fluidValues({marginBottom: [12, 24]})
  }
})

ContentBlockWithShadow.defaultProps = {
}

ContentBlockWithShadow.propTypes = {
}

export default connectFela(styles)(ContentBlockWithShadow)
