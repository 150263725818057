import React from "react"
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'

import Container from "components/container"
import Course from "components/Course"
import P from "components/typography/P"
import UnderlinedTitle from "components/UnderlinedTitle"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import { serializers as linkSerializers } from "components/BaseLink.js"

/* Serializers */
const serializers = ({styles, rules}) => {
  return {
    types: {
      block: (props) => {
        const style = props.node.style
        if (style === 'normal') {
          return <P version='medium' extend={{styles: rules.normal}}>{props.children}</P>
        }
        return BlockContent.defaultSerializers.types.block(props)
      },
    },
    marks: {
      ...linkSerializers(styles),
      highlight: props => (
        <span className={styles.highlight}>
          {props.children}
        </span>
      ),
    }
  }
}

const colorGradient = [50, 100, 200, 300, 400, 500, 600];
const courses = ({ rules, styles, title, intro, color, courses }) => {
  const colorSteps = [...colorGradient].splice(courses.length)
  return <>
    <Container>
      {title && <div className={styles.title}><UnderlinedTitle extend={{styles: rules.overrideTitle}}>
        {title}
      </UnderlinedTitle></div>}

      {intro && <Container noPadding>
        <BlockContent
          renderContainerOnSingleChild
          className={styles.intro}
          blocks={intro}
          serializers={serializers({rules, styles})} />
      </Container>}
    </Container>

    <Container extend={{styles: rules.coursesContainer}}>
      {courses && courses.map((course, index) => {
        return <Course key={index} extend={{styles: rules.course}} bgColor={colors['color' + color + colorSteps[index]]} title={course.title} intro={course._rawIntro} link={course.link} nextcourselink={course.nextcourselink} image={course.image} />
      })}
    </Container>
  </>
 }

/*
 */
const styles = (props) => ({
  title: {
    display: 'flex',
    ...fluidValues({marginBottom: [48, 90]}),
  },
  overrideTitle: {
    marginBottom: '0 !important',
  },
  course: {
    ...fluidValues({marginBottom: [24, 90]}),
  },
  normal: {
    fontWeight: 400,
  },
  intro: {
    ...fluidValues({
      marginBottom: [56, 89],
      paddingLeft: [21, 110],
      paddingRight: [0, 110],
    }),

    '::before': {
      backgroundColor: colors.colorConsole500,
      content: '""',
      display: 'inline-block',
      height: '120%',
      maxHeight: '205px',
      position: 'absolute',
      top: '-20%',
      width: 1,
      left: 0,
    }
  },
  coursesContainer: {
    phoneOnly: {
      paddingRight: '0 !important',
      paddingLeft: '0 !important',
    }
  }
})

export default connectFela(styles)(courses)
