import React from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'

import NextButton from "components/NextButton"
import PrevButton from "components/PrevButton"
import P from "components/typography/P"
import fluidValues from "utilities/fluidValues"
import colors from "theme/colors"
import quote from "images/quote.svg"
import imageUrlFor from "utilities/imageUrlFor"
import AuthorLine from "components/AuthorLine"

/**
*/
const Testimony = ({rules, styles, name, photo, position, company, content, shape, next, last, showButtons}) => {
  return (<div className={styles.styles}>
      <div className={styles.photoContainer}>
          {photo ?
           <picture>
                   <source
                       srcSet={`
 ${imageUrlFor(photo).width(218).height(344).format('webp').url()} 218w,
 ${imageUrlFor(photo).width(218).height(344).format('webp').dpr(2).url()} 218w 2x`}
                       media="(min-width: 600px)"
                       sizes="218px" />

                       <source
                           media="(max-width: 599px)"
                           sizes="207px"
                           srcSet={`
${imageUrlFor(photo).width(207).height(131).format('webp').url()} 1x,
${imageUrlFor(photo).width(207).height(131).format('webp').dpr(2).url()} 2x`}
                       />

                   <source
                       srcSet={`
 ${imageUrlFor(photo).width(218).height(344).url()} 218w,
 ${imageUrlFor(photo).width(218).height(344).dpr(2).url()} 218w 2x`}
                       media="(min-width: 600px)"
                       sizes="218px" />

                       <source
                           media="(max-width: 599px)"
                           sizes="207px"
                           srcSet={`
${imageUrlFor(photo).width(207).height(131).url()} 1x,
${imageUrlFor(photo).width(207).height(131).dpr(2).url()} 2x`}
                       />

                       <img
                           src={`${imageUrlFor(photo).width(207).height(131).url()}`}
                           alt={name}
                           loading="lazy" />
           </picture> : <img className={styles.photo} src={quote} alt={""} />}

      </div>
      <div className={styles.buttonsContainer}>
          {showButtons && (last ? <PrevButton onClick={next} /> : <NextButton onClick={next} />)}
      </div>
      <div className={styles.contentContainer}>
          <P version={'quote'}>{content}</P>
          <AuthorLine name={name} position={position} company={company} withBottomBorder />
      </div>
  </div>)
}

/*
 */
const styles = (props) => ({
    styles: {
        display: 'grid',
        gridTemplateAreas: '"photo buttons" "photo content"',
        gridTemplateColumns: '1fr 2fr',
        gridTemplateRows: '1fr 4fr',
        position: 'relative',
        margin: '84px auto 0 auto',
        width: '100%',
        maxWidth: 874,

        phoneOnly: {
            gridTemplateAreas: '"photo buttons" "content content"',
            gridTemplateColumns: '3fr 2fr',
            gridTemplateRows: '131px 1fr',
        },

        onBefore: {
            extend: [{
                condition: props.shape === 'rectangle',
                style: {
                    display: 'block',
                    content: '""',
                    position: 'absolute',
                    backgroundColor: props.color,
                    ...fluidValues({
                        width: [280, 600],
                        height: [135, 234],
                    }),
                    top: 51,
                    left: 0,
                    transform: 'rotate(-45deg) translateX(-28%) translateY(20%)',
                    transformOrigin: 'center top',
                    tabletLandscapeAndUp: {
                        transform: 'rotate(-45deg) translateX(-2333 translateY(4%)',
                    }
                }
            },{
                condition: props.shape === 'moon',
                style: {
                    clipPath: 'circle(63% at 50% 100%)',
                    display: 'block',
                    content: '""',
                    position: 'absolute',
                    backgroundColor: props.color,
                    ...fluidValues({
                        width: [280, 586],
                        height: [135, 293],
                    }),
                    left: 0,
                    transform: 'rotate(-45deg) translateX(-25%)',
                    transformOrigin: 'center top',
                    phoneOnly: {
                        width: 396,
                        clipPath: 'circle(57% at 50% 100%)',
                        transform: 'rotate(-45deg) translateX(-49%) translateY(2%)',
                    }
                }
            }]
        }
    },
    photoContainer: {
        zIndex: 2,
        gridArea: 'photo',
        textAlign: 'right',
        marginRight: 30,

        phoneOnly: {
            display: 'flex',
            alignItems: 'end',
            textAlign: 'left',
        }
    },
    photo: {
        display: 'block',
        marginLeft: 'auto',
        marginRight: '1.5rem',
        objectFit: 'contain',
        width: '100%',

        phoneOnly: {
            height: '100%',
            margin: 0,
            marginLeft: 'auto'
        }
    },
    buttonsContainer: {
        display: 'flex',
        gridArea: 'buttons',
        position: 'relative',
        minHeight: 120,
        phoneOnly: {
            justifyContent: 'start',
            alignItems: 'end',
        },
        '& svg ': {
            height: 'auto',
        }
    },
    contentContainer: {
        position: 'relative',
        gridArea: 'content',
        phoneOnly: {
            marginTop: 24,
            paddingLeft: 42
        }
    },
})

export default connectFela(styles)(Testimony)
