import React from "react"
import PropTypes from "prop-types"
import { connect as connectFela } from "react-fela"

import P from "components/typography/P"
import colors from "theme/colors"

/**
* Pills
*/
const ContentPills = ({ styles, rules, node: { pills } }) => {
  return <div className={styles.container}>
    {pills.map((pill, index) => <P key={`pill-${index}`} tag={'span'} version={'xsmall'} extend={{styles: rules.pill}}>
    {pill}
    </P>)}
  </div>
}

const styles  = ({color}) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    marginBottom: 18,
    marginLeft: -20,
  },
  pill: {
    backgroundColor: colors.colorConsole500,
    borderRadius: 2,
    color: 'white',
    paddingLeft: 12,
    paddingRight: 12,
    textTransform: 'uppercase',
    display: 'inline-flex',
    alignItems: 'center',
    height: 25,
    marginLeft: 20,
    marginBottom: 16,
  },
})

ContentPills.defaultProps = {
}

ContentPills.propTypes = {
}

export default connectFela(styles)(ContentPills)
