import React, { useContext, useState } from "react"
import { connect as connectFela } from "react-fela"
import { graphql } from 'gatsby'

import Testimony from "components/Testimony"
import fluidValues from "utilities/fluidValues"
import ColorContext from "context/ColorContext"
import { getHexColor } from "theme/colors"

export const testimoniesFrag = graphql`
    fragment testimoniesFrag on SanityTestimony {
        content
        shape
        photo {
            crop {
                top
                bottom
                left
                right
            }
            hotspot {
                width
                height
                x
                y
            }
            asset {
                url
            }
        }
        name
        position
        company
    }
`

const Testimonies = ({ rules, styles, testimonies, pageColor}) => {
    const color = useContext(ColorContext);
    const [current, setCurrent] = useState(0)
    const isLast = () => testimonies[current + 1] === undefined
    const next = () => {
        if (isLast()) {
            setCurrent(i => 0)
        } else {
            setCurrent(i => i + 1)
        }
    }
    const transition = -(100 * current)

    return (
        <>
            {testimonies.length > 0 && <div className={styles.container}>
                {testimonies.map((testimony, index) => {
                    return <div key={index} className={styles.anim} style={{transform: `translateX(${transition}%)`}}>
                        <Testimony color={pageColor ?? getHexColor(color, '500')} last={isLast()} showButtons={testimonies.length > 1} next={next} {...testimony} />
                    </div>
                })}
            </div>}
       </>
    )
}

const styles = () => ({
    anim: {
        minWidth: 0,
        flex: '1 0 100%',
        transition: 'transform 0.6s'
    },
    container: {
        display: 'flex',
        justifyContent: 'start',
        height: '100%',
        marginBottom: -20,
        paddingBottom: 20,
        position: 'relative',
        overflow: 'hidden'
    },
    buttonContainer: {
        display: 'flex',
        ...fluidValues({ marginTop: [30, 30] }),

        phoneOnly: {
            display: 'none'
        }
    },
    prevButton: {
        zIndex: 2,
        ...fluidValues({ marginRight: [12, 24] }),
    },
    nextButton: {
        zIndex: 2,
    }
})

export default connectFela(styles)(Testimonies)
