import React from "react"
import { graphql } from "gatsby"
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'

import ClientsBlock from "components/ClientsBlock"
import ColorContext from "context/ColorContext"
import Container from "components/container"
import ContentBlockWithShadow from "components/ContentBlockWithShadow"
import ContentImage from "components/ContentImage"
import ContentPills from "components/ContentPills"
import Courses from "components/Courses"
import H2 from "components/typography/H2"
import H4 from "components/typography/H4"
import Header from './header'
import Layout from "components/layout"
import P from "components/typography/P"
import ParagraphTitle from "components/ParagraphTitle"
import PartnersBlock from "components/PartnersBlock"
import Scroll from 'react-scroll'
import Share from "components/Share"
import Testimonies  from "components/Testimonies"
import Ul from "components/typography/UL"
import colors, { getHexColor } from "theme/colors"
import fluidValues from "utilities/fluidValues"
import fluidFonts from "utilities/fluidFonts"
import slugify from "utilities/slugify"
import { commonMarks } from "utilities/serializers"
import { serializers as linkSerializers } from "components/BaseLink.js"
import calc from 'utilities/calc'
import { fontFamilyMonospace } from "gatsby-theme-fela/fonts"
import Img from 'components/Img'

const getHighlightColor = color => {
    if (color === 'Depth') {
      return colors[`color${color}100`]
    }

    if (color === 'Console') {
      return colors[`color${color}500`]
    }

    if (color === 'Auto') {
      return colors[`color${color}200`]
    }
}

const serializersStyles = {
  large: {
    ...fluidValues({
      paddingLeft: [21, 110],
    })
  },
  xlarge: {
    ...fluidValues({
      paddingLeft: [21, 110],
    })
  },
  paragraph: {
    ...fluidValues({
      paddingLeft: [21, 110],
    })
  },
  paragraphTitle: {
    extend: {
      marginLeft: '0 !important',
    },
  },
  indentedIntroContent: {
    ...fluidValues({
      paddingLeft: [21, 110],
      marginBottom: [48, 120],
    }),
    desktopAndUp: {
      marginLeft: 110,
    },
  },
  lastIndentedIntroContent: {
    ...fluidValues({
      marginBottom: [60, 200],
    }),
  },
  contentBlockWithShadow: {
    ...fluidValues({
      marginTop: [60, 120],
      marginBottom: [60, 120],
    }),
  },
  list: {
    ...fluidValues({
      paddingLeft: [21, 110],
    }),
    margin: '24px auto',
    maxWidth: 650,

    tabletPortraitAndUp: {
      margin: '32px auto',
    },
    '& > li': {
      marginLeft: 13
    }
  },
  contentBlockWithRibbon: {
    ...fluidValues({
      paddingLeft: [21, 110],
      marginBottom: [60, 200],
      marginTop: [120, 240],
    })
  },
  contentPills: {
    ...fluidValues({
      paddingLeft: [21, 110],
    })
  },
  normal: {
    ...fluidValues({
      paddingLeft: [21, 110],
    })
  },
  links: {
    textDecoration: 'underline',
  }
}

const serializers = ({styles, rules}) => {
  return {
    list: props => ( <Ul {...props} type={props.type} extend={{styles: rules.list}}>{props.children}</Ul> ),
    listItem: props => {
      return <P tag={'li'} version={props.node?.listItem === 'dash' ? "list-light" : "list"}>{props.children}</P>
    },
    types: {
      contentPills: props => <ContentPills extend={{container: rules.contentPills}} {...props} />,
      contentBlockWithShadow: (props) => <ContentBlockWithShadow extend={{container: rules.contentBlockWithShadow}} {...props} />,
      contentImage: (props) => <ContentImage {...props} extend={{container: rules.normal}} />,
      indentedIntroContent: (props) => {
        rules.xlarge = (props) => ({ phoneOnly: { marginLeft: 24 }, ...fluidValues({marginRight: [21, 170]})})
        return <BlockContent
                 className={styles.indentedIntroContent + " " + (props.node.lastOne ? styles.lastIndentedIntroContent : '')}
                 renderContainerOnSingleChild
                 blocks={props.node.content}
                 serializers={serializers({rules, styles})} />
      },
      block: (props) => {
        const style = props.node.style

        if (style === 'xlarge') {
          return <P extend={{styles: rules.xlarge}} version="xlarge">{props.children}</P>
        }

        if (style === 'title') {
          return <ParagraphTitle extend={{container: rules.paragraphTitle}}>{props.children}</ParagraphTitle>
        }

        if (style === 'large') {
          return <P version='large' extend={{styles: rules.large}}>{props.children}</P>
        }

        if (style === 'normal') {
          return <P version='body' extend={{styles: rules.normal}}>{props.children}</P>
        }

        return BlockContent.defaultSerializers.types.block(props)
      },
    },
    marks: {
      ...linkSerializers(styles, rules),
      ...commonMarks({styles, rules}),
    }
  }
}

const navActive = {
  '@media (min-width: 1000px)': {
    onAfter: {
      position: 'absolute',
      content: "''",
      backgroundColor: 'black',
      display: 'block',
      width: '110%',
      height: 11,
      bottom: -11,
    }
  }
}

const navActiveMobile = {
  '@media (max-width: 1000px)': {
    onAfter: {
      position: 'absolute',
      content: "''",
      backgroundColor: 'black',
      display: 'block',
      ...fluidValues({
        left: [-43, -110],
      }),
      width: 10,
      height: '100%',
    }
  }
}
const isCurrentSection = (item) => typeof window !== 'undefined' && window.location.href.includes(slugify(item))
const scrollTo = (anchor) => Scroll.scroller.scrollTo(anchor, {smooth: true,})

export default connectFela(({data: { sanityTrainingPage: { color }}}) => ({
  shareContainer: {
    backgroundColor: getHexColor(color, '100'),
    justifyContent: 'end',
    alignItems: 'center',
    ...fluidValues({
      height: [72, 136],
      marginBottom: [60, 120]
    })
  },
  socialcontainer: {
    width: '100%',
    maxWidth: 1129,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'right',
  },
  social: {
    ...fluidValues({
      marginLeft: [6, 12]
    }),
  },
  currentSection: {
    ...navActive
  },
  currentSectionMobile: {
    ...navActiveMobile
  },
  navContainer: {
    backgroundColor: getHexColor(color, '100'),
    phoneOnly: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    }
  },
  navInnerContainer: {
    boxSizing: 'border-box',
    maxWidth: 1129,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'noWrap',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...fluidValues({
      paddingTop: [32, 85],
      paddingLeft: [43, 110],
      paddingRight: [43, 110],
      paddingBottom: [43, 134],
    })
  },
  navHeaders: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > h2:last-child': {
      textAlign: 'left',
      maxWidth: 329,
      width: '100%',
      '@media (max-width: 1000px)': {
        display: 'none',
      }
    }
  },
  navRows: {
    display: 'flex',
    flexDirection: 'column'
  },
  navRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 77,
    flexWrap: 'wrap',
    borderBottom: '1px solid black',
    '@media (max-width: 1000px)': {
      borderBottom: 'none',
      marginBottom: 21
    },
    onHover: {
      '& > h4' :{
        ...navActive
      }
    },
    '& > h4': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      '@media (max-width: 999px)': {
        width: '100%'
      },
    },
    '& > p': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 329,
      textAlign: 'left',
      '@media (max-width: 1000px)': {
        maxWidth: '100%'
      },
    },
    justifyContent: 'space-between',
  },

  content: {
    '@media(max-width: 768px)': {
      paddingLeft: calc(0, 60, 320, 768),
      paddingRight: calc(0, 60, 320, 768)
    },
    paddingLeft: calc(60, 0, 768, 1440),
    paddingRight: calc(60, 0, 768, 1440),
  },

  contentContainer: {
    maxWidth: 1220,
    ...fluidValues({
      marginBottom: [60, 120],
      paddingLeft: [21, 110],
      paddingRight: [21, 110],
    }),
  },
  courseContent: {
    ...fluidValues({marginBottom: [60, 120]}),
    maxWidth: 1220,
  },
  courseContentContent: {
    ...fluidValues({
      paddingLeft: [21, 110],
      paddingRight: [21, 110],
    }),
  },
  courses: {
    extend: {
      ...fluidValues({marginBottom: [90, 200]})
    },
  },
  testimonies: {
    extend: {
        ...fluidValues({
            minHeight: [200, 640],
            paddingRight: [0, 280],
            marginBottom: [90, 200]
        }),
    },
      phoneOnly: {
          paddingLeft: '0 !important',
    }
  },
  clients: {
    ...fluidValues({
      marginBottom: [90, 200]
    })
  },
  partners: {
    ...fluidValues({
      marginBottom: [90, 200]
    })
  },
  more: {
    fontFamily: fontFamilyMonospace,
    paddingTop: 10,
    ...fluidFonts({fontSize: [10, 18], lineHeight: [18, 24]}),
  },
  highlight: {
    display: 'inline',
    backgroundColor: getHighlightColor(color),
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 3,
    paddingBottom: 3,
  },
  badge: {
    position: 'absolute !important',
    marginLeft: 'auto !important',
    marginRight: 'auto !important',
    left: 0,
    right: 0,
    ...fluidValues({
      width: [70, 145],
      height: [70, 145],
      top: [-35, -72.5]
    }),
    phoneOnly: {
      marginRight: '1rem'
    }
  },
  ...serializersStyles
}))(({
  path,
  pageContext,
  data: {
    jobs,
    settings,
    sanityTrainingPage : {
      title,
      meta,
      slug,
      color,
      header,
      consultSections,
      clients,
      _rawPartners,
      partners,
      _rawContent,
      testimoniesBlock,
      courses,
      nonsafecourses,
      badge
    }
  }, rules, styles
}) => {
  const coursesMenuItems = [...courses?.courses ?? [], ...nonsafecourses?.courses ?? []].filter((course) => course !== undefined)
  return (
    <ColorContext.Provider value={color}>
      <Layout
        pageColor={getHexColor(color, '100')}
        linkColor={getHexColor(color, '200')}
        title={title}
        slug={{current: path }}
        meta={meta || {}}
        settings={settings}
        jobs={jobs}
        country={pageContext.country}
      >
        {header && <Header
                     {...header}
                     header
                     extend={{styles: rules.header}}
                     copy={header._rawCopy}
                     hidesidebar={false}
                     bgColor={getHexColor(color, '100')}
                     color={(header.fontcolor !== 'Light' ? getHexColor('Console', '500') : getHexColor('Canvas', '50'))}
                   />}

        {coursesMenuItems.length > 0 &&
         <Container tag={'section'} extend={{styles: rules.navContainer}}>

           {badge && <Img credits={''} className={styles.badge} image={badge.asset?.gatsbyImageData} alt={""} />}

           <div className={styles.navInnerContainer}>
             <div className={styles.navHeaders}>
               <H2>Our courses</H2>
               <H2></H2>
             </div>

             <div className={styles.navRows}>
               {coursesMenuItems.map((section, index) => {
                 return <div className={styles.navRow + " " + (isCurrentSection(section.title) ? styles.currentSectionMobile : '')} key={'course-menu-item' + index}>
                          <H4 extend={{styles: isCurrentSection(section.title) ? rules.currentSection : ''}}>
                            <a href={"#" + slugify(section.title)} onClick={() => scrollTo(slugify(section.title))}>{section.title}</a>
                          </H4>
                          <p className={styles.more}><a href={"#" + slugify(section.title)} onClick={() => scrollTo(slugify(section.title))}>MORE</a></p>
                        </div>
               })}
             </div>
           </div>
         </Container>}


        <Container flex tag={'section'} extend={{styles: rules.shareContainer}}>
          <div className={styles.socialcontainer}>
            <Share
              extend={{social: rules.social}}
              bgColor={getHexColor(color, '100')}
              url={typeof window !== 'undefined' && window.location.href}
              country={"nz"}
            />
          </div>
        </Container>

          {_rawContent && <Container withContentArea4 noPadding tag={'section'} extend={{styles: rules.container}}>
              <Container extend={{styles: rules.contentContainer}}>
                <BlockContent className={styles.content} blocks={_rawContent.content} serializers={serializers({rules, styles})}
                              imageOptions={{w: 1263, h: 1016, fit: 'max'}}
                              projectId={process.env.SANITY_PROJECT_ID || process.env.GATSBY_SANITY_PROJECT_ID}
                              dataset={process.env.SANITY_DATASET || process.env.GATSBY_SANITY_DATASET}
                />
              </Container>
          </Container>}

        {courses && <Container noPadding withContentArea3 tag={'section'} extend={{styles: rules.courses}} id={'courses'}>
                      <Courses color={color} title={courses.title} intro={courses._rawIntro} courses={courses.courses} />
                    </Container>}

        {nonsafecourses && <Container noPadding withContentArea3 tag={'section'} extend={{styles: rules.nonsafecourses}} id={'nonsafecourses'}>
                             <Courses color={'Canvas'} title={nonsafecourses.title} intro={nonsafecourses._rawIntro} courses={nonsafecourses.courses} />
                           </Container>}

        {testimoniesBlock && <Container extraLeft withContentArea5 tag={'section'} extend={{styles: rules.testimonies}}>
                               <Testimonies testimonies={testimoniesBlock.testimonies} pageColor={getHexColor(color, '100')} />
                             </Container>}

        {clients &&
         <Container extraLeft withContentArea5 tag={'section'} extend={{styles: rules.clients}}>
           <ClientsBlock title={clients.title} filters={clients.tags} featured={clients.featured} />
         </Container>}

        {partners && <Container extraLeft withContentArea5 tag={'section'} extend={{styles: rules.partners}}>
                       <PartnersBlock
                         pageColor={getHexColor(color, '100')}
                         title={partners.title}
                         content={_rawPartners.content}
                         featured={partners.partners}
                         circle />
                     </Container>}
      </Layout>
    </ColorContext.Provider>
  )
})

export const pageQuery = graphql`
query($country: String!, $slug: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityTrainingPage(slug: { current: { eq: $slug }}) {
      countrySet
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      color
      header {
        title
        _rawCopy
        link {
          ...sanityLinkFrag
        }
        category
        image {
          credits
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
          }
        }
        fontcolor
      }

      badge {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width:  1440, placeholder: NONE)
        }
      }

      _rawContent(resolveReferences: {maxDepth: 10})

      courses {
        title
        _rawIntro

        courses {
          title
          safe

          _rawIntro
          link {
            ...sanityLinkFrag
          }

          nextcourselink {
            ...sanityLinkFrag
          }

          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width:  218, placeholder: NONE)
            }
          }
        }
      }

      nonsafecourses {
        title
        _rawIntro

        courses {
          title
          safe

          _rawIntro
          link {
            label
            ...sanityLinkFrag
          }

          nextcourselink {
            ...sanityLinkFrag
          }

          image {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width:  218, placeholder: NONE)
            }
          }
        }
      }

      testimoniesBlock {
          testimonies {
               ...testimoniesFrag
          }
      }

      clients {
        title
        tags
        featured {
          name
          description
        }
      }

      _rawPartners
      partners {
        title
        partners {
          name
          description
          link {
            ...sanityLinkFrag
          }
        }
      }
    }
  }
`
