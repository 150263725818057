import React from "react"
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

import Button from "components/Button"
import H4 from "components/typography/H4"
import P from "components/typography/P"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import fluidDimensions from "utilities/fluidDimensions"
import { serializers as linkSerializers } from "components/BaseLink"
import slugify from "utilities/slugify"

const serializers = ({styles, rules}) => {
  return {
    marks: {
      ...linkSerializers(styles, rules),
    },
    types: {
      block: (props) => {
        return <P version='body'>{props.children}</P>
      },
    },
  }
}

const course = ({rules, styles, title, intro, link, image, nextcourselink}) => (
  <div className={styles.styles} id={slugify(title)}>
    <div className={styles.textContainer}>
      {title && <H4 extend={{styles: rules.title}}>
        {title}
      </H4>}

      {intro &&
       <BlockContent
         renderContainerOnSingleChild
         className={styles.intro}
         blocks={intro}
         serializers={serializers({rules, styles})}
       />}

      <Button to={link} extend={{styles: rules.firstButton}}>{link.label}</Button>
      {nextcourselink?.label && <Button to={nextcourselink} extend={{styles: rules.button}}>{nextcourselink.label}</Button>}
    </div>

    {image && <div className={styles.image} >
      <Img image={image.asset.gatsbyImageData} alt={ title } />
    </div>}
  </div>
)

/*
 * Only style the inside, the asymertric alignment should be dealt with in the parent components
 */
const styles = (props) => ({
  styles: {
    backgroundColor: props.bgColor || colors.colorHuman400,
    ...fluidValues({
      paddingTop: [48, 90],
      paddingBottom: [48, 90],
      paddingLeft: [43, 110],
      paddingRight: [12, 75],
    }),
    position: 'relative',
  },
  links: {
    color: colors.colorHuman900,
  },
  image: {
    position: 'absolute',
    top: '-10%',
    ...fluidDimensions({
      width: [67, 218],
    }),
    phoneOnly: {
      top: '-6%',
    },
    extend: {
      ...fluidValues({
        right: [12, 75],
      }),
    }
  },
  textContainer: {
    ...fluidValues({
      maxWidth: [240, 625],
    }),
  },
  title: {
    marginTop: 0,
  },
  firstButton: {
    backgroundColor: "#FFFFFF",
    maxWidth: 405,
    ...fluidValues({
      marginBottom: [12, 24],
      marginRight: [12, 24],
    })
  },
  button: {
    backgroundColor: "#FFFFFF",
    maxWidth: 405,
  },
  intro: {
    ...fluidValues({
      marginBottom: [12, 32],
    }),
  },
  normal: {
    fontWeight: 400,
  }
})

export default connectFela(styles)(course)
